@import '../../sass/variables.scss';
@import '../../sass/responsive.scss';

.content-wrapper {
	position: relative;
	z-index: 10;

	.sidebar {
		height: 100%;
		width: 4em;
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding-top: 5em;
		background: $base;
		font-size: 1.2rem;
		font-weight: 600;
		letter-spacing: 1px;
		transition: all .2s ease-in-out;

		.nav {
			margin-top: 1em;
			position: relative;
			.nav-link {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: 100%;
				padding: .6em 1em;
				margin: 0 0 .5em;
				text-decoration: none;
				color: $white-light;
				border: 1px solid transparent;
				border-radius: 5px;
				overflow: hidden;

				&:hover {
					background: hsla(0,0%,100%,.3);
					color: $white;
				}

				svg {
					text-align: center;
					transition: all .2s ease-in-out;
				}
				span {
					margin-left: 3em;
					white-space: nowrap;
					width: 0;
					display: none;
					opacity: 0;
					overflow: hidden;
				}
			}
			.nav-link.active {
				color: $white;
				span {
					text-decoration: underline;
				}
			}
		}

		footer {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			background: $base-dark;
			color: $white-light;
			width: 100%;
			padding: .6em 1em;
			margin: 1em;
			margin-bottom: 3em;
			border: 1px solid transparent;
			border-radius: 5px;
			transition: all .2s ease-out;

			.img_container {
				position: relative;
				display: inline-block;
				right: 0;
				margin-right: auto;
				height: 2.5em;
				width: 100em;
				max-width: 2.5em;
				
				img.profile_pic {
					display: inline;
					margin: 0 auto;
					height: 100%;
					width: 100%;
					background-color: $white;
					border: 2px solid $white;
					border-radius: 50%;
					object-fit: cover;
					visibility: hidden;
					opacity: 0;
					transition: all 0.1s ease-in;
				}
			}

			// img {
			// 	position: relative;
			// 	right: 0;
			// 	height: 2.5em;
			// 	margin-right: auto;
			// 	visibility: hidden;
			// 	opacity: 0;
			// 	transition: all 0.2s ease-in-out;
			// }
			.user-details {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: $white-light;
				font-size: 1.2rem;
				font-weight: 600;
				cursor: pointer;
				&:hover {
					color: $white;
				}

				span {
					margin: 0 10px;
					visibility: hidden;
					opacity: 0;
					transition: visibility, opacity 0.2s ease-in-out;
				}
				svg {
					transition: visibility, opacity 0.2s ease-in-out;
				}
				
			}
		}

		&:hover {
			width: 16em;
			padding: 5em .5em .5em;
			transition: all 0.2s ease-in-out;
			.nav-link {
				svg {
					height: 24px;
					width: 24px;
				}
				span {
					position: absolute;
					display: inline-block;
					width: auto;
					// height: 100%;
					opacity: 1;
					transition: opacity 0.2s ease-in;
				}
			}
			footer {
				margin: 0;
				margin-bottom: 2em;
				img.profile_pic {
					visibility: visible;
					opacity: 1;
				}
				span {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		@media screen and (max-width: $tablet) {
			margin-left: -16em;
			transition: all 0.2s ease-in-out;
		}
	}


	.sidebar.show {
		display: flex;
		width: 16em;
		margin-left: 0;
		padding: 5em .5em .5em;
		transition: all 0.2s ease-in-out;
		.nav-link {
			span {
				position: absolute;
				display: inline-block;
				width: auto;
				opacity: 1;
				transition: opacity 0.2s ease-in;
			}
		}
		footer {
			margin: 0;
			margin-bottom: 2em;
			// justify-content: space-between;
			img.profile_pic {
				visibility: visible;
				opacity: 1;
			}
			span {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	@media screen and (max-width: $tablet) {
		position: absolute;
	}
}


.sidebar-intersect {
    position: absolute;
    background: url("/img/pcm_sidebar_intersect.svg") left top no-repeat;
    background-size: cover;
    background-color: transparent;
    width: 50px;
    height: 50px;
    left: 92%;
    top: -3.76%;
    z-index: 0;
}