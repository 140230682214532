@import '../../sass/variables.scss';

.card {
    background: $white;
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 15px 16.83px .17px rgba(0, 0, 0, .05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .card-img {
        height: 5em;
        width: auto;
        object-fit: cover;
        margin-right: .5em;
    }

    .card-body {
        color: $base;
        padding: .2em;
        text-align: end;
        
        .card-title {
            // font-size: 2rem;
            font-size: clamp(1.6rem, 2.5cqi, 1.6rem);
            font-weight: 800;
            letter-spacing: 1px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            word-break: break-word;
        }

        .card-subtitle {
            margin-bottom: 0.5rem;
            color: $text;
            font-size: 1.2rem;
            font-weight: 600;
            word-break: break-word;
        }

        ul#tags {
            margin: 0;
            justify-content: flex-end;

            li.tag {
                margin-bottom: 0;
                height: 2.4em;
            }

            li.tag.text-wrapped {
                margin-bottom: 8px;
            }
        }
    }

    .card-body.text-area {
        text-align: start;
        // padding: 1em;
        height: 100%;
        // min-height: 12em;
    }
}

.hide-card {
    display: none;
    visibility: hidden;
    opacity: 0;
}