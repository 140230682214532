$body: #e2e2e2;
$body-light: #eeeeeeb3;
$text: #415860;
$base-light: #29dabb;
$base: #17a395;
$base-dark: #028a7c;
$base-glassMorph: rgba(23, 163, 149, 0.5);

//form variables
//$input: #999;
$input: #bebebe;
$input-error: #d80f0f;
$input-ok: #198754;
$ad: #dc3545;

//modal
$model-success: #07bc0c;
$model-error: #e74c3c;

//profile active
$active: #26af48;
$active-overlay: rgba(15, 183, 107,0.12);
$deactive: #e63c3c;
$deactive-overlay: rgba(242, 17, 54, 0.12);

$white: #fff;
$white-light: #ffffffb3;
$red: #ff0000;


$tab-orange-light: #FFF6EE;
$tab-orange: #FFC48B;
$tab-orange-dark: #fe9530;
$tab-orange-opacity: rgba(255, 195, 139, 0.2);

$tab-blue-light: #e6faff;
$tab-blue: #7cdefc;
$tab-blue-dark: #0bc6ff;
$tab-blue-opacity: rgba(124, 222, 252, 0.2);

$tab-green-light: #e5fff8;
$tab-green: #76f6d6;
$tab-green-dark: #09da9e;
$tab-green-opacity: rgba(118, 246, 214, 0.2);

$tab-pink-light: #FFF0F3;
$tab-pink: #FFB3C0;
$tab-pink-dark: #ff526f;
$tab-pink-opacity: rgba(255, 179, 192, 0.2);

$tab-violet-light: #eeeeff;;
$tab-violet: #B4B3FF;
$tab-violet-dark: #716fff;
$tab-violet-opacity: rgba(180, 179, 255, 0.2);

$tab-cyan-light: #E3FCFD;
$tab-cyan: #7afbff;
$tab-cyan-dark: #18e3ea;
$tab-cyan-opacity: rgba(122, 251, 255, 0.2);



$tab-two-light: #FAFAFF;
$tab-two: #B4B3FF;
$tab-three-light: #FFF0F3;
$tab-three: #FFB3C0;
