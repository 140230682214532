@import '../../sass/responsive.scss';
@import '../../sass/variables.scss';

.hero {
    padding: 5cqi;
    position: relative;
    background-color: $base;
    // @media screen and (max-width: $laptop) {
    //     padding: 2em;
    // }

    .text {
        padding: 5em 2em 0em;
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        @media screen and (max-width: $tablet) {
            padding: 2em;
            align-items: center;
            text-align: center;
        }
        // @media screen and (max-width: $mobile) {
        //     padding: 1em;
        // }

        h1 {
            font-size: clamp(2.4rem, 3cqi, 4rem);
            font-weight: 600;
            color: $white;
            // @media screen and (max-width: $laptop) {
            //     font-size: 3rem;
            // }
            // @media screen and (max-width: $mobile) {
            //     font-size: 2.5rem;
            // }
        }

        p {
            font-size: clamp(1.2rem, 1.2cqi, 2rem);
            font-weight: 450;
            padding-right: 8cqi;
            padding-bottom: 1em;
            @media screen and (max-width: $tablet) {
                padding-right: 0;
            }
            // @media screen and (max-width: $mobile) {
            //     font-size: 1rem;
            // }
        }

        .btn.btn-primary,
        .btn.btn-primary:focus {
            background-color: $white;
            color: $base;
            outline: 0;
            border: 0;
            padding: .6em 1em;
            border-radius: 5px;
            outline: none;
            font-size: clamp(1.2rem, 1cqi, 1.5rem);
            font-weight: 600;
            letter-spacing: 1px;
            cursor: pointer;
            box-shadow: none;
            // @media screen and (max-width: $mobile) {
            //     font-size: 1.2rem;
            // }
        }

        .btn.btn-primary:hover {
            background-color: $base-dark;
            color: $white;
        }

        .hover_me {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            user-select: none;
            @media screen and (max-width: $tablet) {
                justify-content: center;
            }
            .arrow_with_text {
                display: inline-flex;
                @media screen and (max-width: $tablet) {
                    display: none;
                }
                h3 {
                    color: $white;
                    margin: 0 auto 0 5em;
                }
                svg {
                    position: absolute;
                    top: -50px;
                    right: 40px;
                    width: 150px;
                    height: 150px;
                    margin: 0 auto;
                    rotate: 277deg;
                    .draw-arrow {  
                        stroke-width: 2;
                        stroke: $white; 
                        fill: none;
                        stroke-dasharray: 400;
                        stroke-dashoffset: 400; 
                        animation-duration: 4s;
                        animation-fill-mode: forwards;
                        animation-name: draw;
                        &.tail-1 {
                            animation-delay: .8s;
                        }
                        &.tail-2 {
                            animation-delay: 1s;
                        }
                    }
                }
                @keyframes draw {
                    to {
                        stroke-dashoffset: 0;
                    }
                }
            }
        }
    }


    .hero_contacts {
        display: inline-block;
        position: absolute;
        top: 11%;
        height: auto;
        width: 100%;
        max-width: 70em;
        user-select: none;
        box-shadow: 0 20px 16.83px .17px rgba(0, 0, 0, .08);
        // @media screen and (max-width: $laptop) {
        //     top: 20%;
        // }

        img {
            border-radius: 5px;
            display: inline;
            object-fit: cover;
            max-height: 100%;
            max-width: 100%;
        }
    }

    .hero_dashboard {
        // display: inline-block;
        position: absolute;
        width: 15cqh;
        top: 52%;
        left: 0%;
        display: inline-flex;
        gap: .8em;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        user-select: none;
        @media screen and (max-width: $tablet ) {
            display: none!important;
        }

        .hero_card {
            display: inline-flex;
            // width: 8cqi;
            width: auto;
            box-shadow: 0 20px 16.83px .17px rgba(0, 0, 0, .1);
            // margin: 5px 0;
            border-radius: 8px;
            padding: .5em;
            cursor: pointer;
            transition: 0.6s ease all;
            opacity: 0.5;
            filter: blur(1px);
            transform:
                perspective(800px) rotateY(20deg) scale(0.9) rotateX(10deg);
                
            .card-img {
                height: clamp(1em, 5em, 2em);
                width: auto;
                object-fit: cover;
                padding-right: 10px;
            }

            .card-body {
                text-align: end;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .card-title {
                    font-size: clamp(.8rem, 4em, 1rem);
                    font-weight: 800;
                    margin-bottom: 0;
                    white-space: nowrap;
                }
                .card-subtitle {
                    font-size: clamp(.1rem, 2cqi, .9rem);
                    font-weight: 600;
                    padding-top: 5px;
                    margin-bottom: 0;
                    white-space: nowrap;
                }
            }

            &:hover {
                box-shadow: 0 15px 10px .17px rgba(0, 0, 0, .2);
                filter: blur(0);
                opacity: 1;
                transform:
                    translateY(-15px) perspective(800px) rotateY(-15deg) rotateX(10deg) scale(1);
            }
        }

        .hero_card:nth-child(1) {
            // width: 15cqi;
            img {
                padding-right: 5em;
            }
            &:hover {
                box-shadow: 0 24px 20px .26px rgba(0, 0, 0, .2);
                transform:
                    translateY(-24px) perspective(800px) rotateY(-15deg) rotateX(10deg) scale(1)
            }
        }

        .hero_card:nth-child(2) {
            // width: 13cqi;
            img {
                padding-right: 3em;
            }
            &:hover {
                box-shadow: 0 21px 15px .23px rgba(0, 0, 0, .2);
                transform:
                    translateY(-21px) perspective(800px) rotateY(-15deg) rotateX(10deg) scale(1)
            }
        }

        .hero_card:nth-child(3) {
            // width: 11.1cqi;
            &:hover {
                box-shadow: 0 18px 12px .20px rgba(0, 0, 0, .2);
                transform:
                    translateY(-18px) perspective(800px) rotateY(-15deg) rotateX(10deg) scale(1)
            }
        }
    }
}