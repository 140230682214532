@import '../.././sass/variables.scss';

.glass-border {
    position: relative;
    display: inline-block;
    height: 12em;
    width: 100em;
    max-width: 12em;

    .profile_pic {
        display: inline;
        margin: 0 auto;
        // margin-left: -25%;
        height: 100%;
        width: 100%;
        background-color: $white;
        border: 5px solid $white;
        border-radius: 50%;
        object-fit: cover;
    }

    .active-overlay {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: .5em;
        background-color: $active;
        color: $active;
        border: 6px solid $body;
        border-radius: 50%;
        cursor: pointer;
    }

    .favorite-overlay {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: .2em;
        background-color: $body;
        border: 6px solid $body;
        border-radius: 50%;
        cursor: pointer;

        .favorite-icon.ok {
            color: $input-error;
        }
        .favorite-icon.no {
            // color: $text;
            color: $white;
        }
    }
}

.glass-border.on {
    // background: rgba(224, 151, 151, 0.18);
    border: 10px solid rgba(255, 255, 255, 0.18);
    box-sizing: border-box;
    border-radius: 50%;
}

.glass-border.off {
    border: none;
    border-radius: 0%;
}