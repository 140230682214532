@import '../../sass/variables.scss';

.display-table {
    min-height: 24vw;

    table {
        border-collapse: separate;
        border-spacing: 0 .6em;
        white-space: nowrap;

        thead {
            position: relative;

            tr {
                color: $text;
                font-size: 1.3rem;
                font-weight: 800;
                letter-spacing: 1px;
                text-transform: uppercase;

                th {
                    padding: .1em .6em;
                    vertical-align: middle;
                }
                th.id {
                    padding: 0 1.1em;
                }
                th.name {
                    padding-right: 7em;
                }
                th.email {
                    padding-right: 9em;
                }
            }
        }

        tbody {
            position: relative;

            .data-row {
                color: $base;
                background-color: $white;
                font-size: 1.1rem;
                font-weight: 500;
                display: table-row;
                cursor: pointer;

                td {
                    padding: .4em .6em;
                    vertical-align: middle;
                    // word-wrap: break-word;

                    .img-border {
                        position: relative;
                        display: inline-block;
                        height: 3em;
                        width: 100%;
                        max-width: 3em;

                        .profile_pic {
                            display: inline;
                            height: 100%;
                            width: 100%;
                            background-color: $white;
                            border: 2px solid $text;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        .country_flag {
                            display: inline;
                            height: 100%;
                            width: 100%;
                            background-color: $white;
                            border-radius: 25%;
                            object-fit: cover;
                        }
                    }

                    .btn {
                        padding: .4em;

                        // svg {
                        //     height: 16px;
                        //     width: 18px;
                        // }
                    }

                    .btn.edit {
                        border: 2px solid $base;
                        color: $white;
                        background-color: $base;
                    }

                    .btn.delete {
                        border: 2px solid $deactive;
                        color: $deactive;
                        fill: $deactive;

                        svg {
                            path {
                                stroke: $deactive;
                            }
                        }
                    }
                }

                &:hover {
                    color: $white;
                    background-color: $base;

                    .profile_pic {
                        border: 2px solid $white !important;
                    }

                    .btn.edit {
                        border: 2px solid $white;
                        color: $base;
                        background-color: $white;

                        &:hover {
                            border: 2px solid $base-dark;
                            color: $white;
                            background-color: $base-dark;
                        }
                    }

                    .btn.delete {
                        border: 2px solid $white;
                        color: $deactive;
                        background-color: $white;

                        &:hover {
                            border: 2px solid $deactive;
                            color: $white;
                            background-color: $deactive;
                            svg {
                                path {
                                    stroke: $white;
                                }
                            }
                        }
                    }
                }
            }
            .data-row.selected {
                background-color: $body-light;
                &:hover {
                    background-color: $base;
                }
            }

            tr.no-user {
                background-color: transparent;
                color: $base;
                font-size: 1.8rem;
                font-weight: 400;
                cursor: default;
                .content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        margin-top: -1px;
                        path {
                            stroke: $base;
                        }
                    }
                }
            }
        }
    }
}


input[type=checkbox] {
    height: 16px;
    width: 16px;
    // margin-top: 0;
    display: inline-block;
    border-color: $base-dark;
    outline: none;
    cursor: pointer;
}

input[type=checkbox]:checked {
    outline: none;
    background-color: $base-dark;
    border-color: $base-dark;
}