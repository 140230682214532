@import './variables.scss';
@import './responsive.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

html {
  position: relative;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 0;
  left: 0;
  background-color: $body !important;
  font-family: 'Poppins';
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.o {
  outline: 1px solid $red;
}

.b {
  // background: rgba(106, 222, 106, 44%) !important;
  background: rgba(222, 106, 106, .26) !important;
}

a {
  // text-decoration: none;
}

a.underline:hover {
  text-decoration: underline;
}

.Toastify {
  .Toastify__toast-container {
    top: 5em;
  }
}

.loader {
  position: absolute;
  backdrop-filter: blur(2px);
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  transition: all .2s ease-in-out;

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
    & .path {
      stroke: rgba(255, 255, 255, 0.8);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}

.base {
  .loader {
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
  }
}

.private_base {
  .loader {
    top: 0px;
    height: 100vh;
    width: 100vw;
    border-radius: 10px 0 0;
    @media screen and (max-width: $tablet) {
      left: 0px!important;
      width: 100vw!important;
    }
  }
}
.private_base.slideBar_open  {
  .loader {
    left: 19.2em;
    width: 85vw;
  }
}
.private_base.slideBar_close {
  .loader {
    left: 4.8em;
    width: 97vw;
  }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media (min-width: 1200px) {
  ::-webkit-scrollbar {
    width: 8px;
    background-color: $body;
    opacity: 0;
  }
}

@media (min-width: 1200px) {
  ::-webkit-scrollbar-thumb {
    background-color: $base;
    border-radius: 0px;
  }
}

.public_pages {
  margin-top: 53px;
}

.private_base {
  background: $base;
}

.private_pages {
  margin-top: 4.5em;
  transition: all 0.2s ease-in-out;
  // border: 1px solid transparent;
  border-radius: 10px 0 0 0;
  background: $body;
  @media screen and (max-width: $tablet) {
    border-radius: 0;
    border: none;
  }
}

.private_pages .content {
  padding: 2em;

  @media screen and (max-width: $mobile-s) {
    padding: 2em 1em;
  }
}

.slideBar_open .private_pages {
  margin-left: 19.2em;

  @media screen and (max-width: $tablet) {
    margin-left: 0em;
    transition: background-color .1s;
  }
}

.slideBar_close .private_pages {
  margin-left: 4.8em;

  @media screen and (max-width: $tablet) {
    margin-left: 0em;
  }
}

.slidebar-overlay {
  @media screen and (max-width: $tablet) {
    display: none;
    position: fixed;
    left: 0;
    top: 4em;
    z-index: 8;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.slidebar-overlay.open {
  @media screen and (max-width: $tablet) {
    display: block;
  }
}

.no_match_found {
  text-align: center;
  color: $text;
  .error_img {
    margin: 0 auto;
    max-width: 40em;
    padding: 2em 0;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .text {
      font-size: 4.5em;
      @media screen and (max-width: $mobile) {
        font-size: 3em;
      }
    }
  }
  .text_sm {
    opacity: .6;
    @media screen and (max-width: $mobile) {
      font-size: 1.1em;
    }
  }
  a{
    text-decoration: none;
  }
}