@import '../../sass//variables.scss';
@import '../../sass/responsive.scss';

.action_button {
    margin-top: 2em;

    .three {
        width: 50%;
        @media screen and (max-width: $laptop) {
            width: 80%;
        }
        @media screen and (max-width: $tablet) {
            width: 100%;
        }
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        color: $base;
        width: auto;
        // margin-right: 30px;
        padding: .6em 1em;
        border: 2px solid $base;
        border-radius: 5px;
        outline: none;
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 1px;
        cursor: pointer;

        &:focus {
            box-shadow: 0 0 0 0.25rem transparent;
        }

        &:hover {
            background: $base-dark;
            border: 2px solid $base-dark;
            color: $white;
        }
    }

    .btn.fill {
        background-color: $base;
        color: $white;
        &:hover {
            background: $base-dark;
            color: $white;
        }
    }

    .btn.danger {
        border: 2px solid $deactive;
        color: $deactive;
        .ico {
            margin-top: -7px;
            svg {
                path {
                    stroke: $deactive;
                }
            }
        }

        &:hover {
            background: $deactive;
            color: $white;
            svg {
                path {
                    stroke: $white;
                }
            }
        }
    }

    .btn:disabled {
        cursor: not-allowed;
    }

    // .btn.form_reset {
    //     background: transparent;
    //     color: $base;
    //     border: 2px solid $base;

    //     &:hover {
    //         background: $base-dark;
    //         color: $white;
    //         border: 2px solid $base-dark;
    //     }
    // }

    // .btn.form_reset.red {
    //     border: 2px solid $deactive;
    //     color: $deactive;

    //     &:hover {
    //         background: $deactive;
    //         color: $white;
    //         border: 2px solid $deactive;
    //     }
    // }
}

.action_button.left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.action_button.center {
    display: flex;
    justify-content: center;
    align-items: center;
}