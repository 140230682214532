@import '../../sass/variables.scss';
@import '../../sass/responsive.scss';

.bg-navbar {
	background: $base;
}

.navbar {
	color: $white-light;
	padding-top: .3rem;
	padding-bottom: .3rem;
	// max-height: 54.6px;

	.container-fluid {

		.navbar-header {
			img {
				width: 2em;
			}
			a.navbar-brand {
				font-weight: 600;
				letter-spacing: 1px;
				font-size: 1.9rem;
				padding: 0;
				transition: all .2s ease-in-out;
				
				@media screen and (max-width: $mobile) {
					font-size: 1.8rem;
					letter-spacing: .1px;
					margin-right: .1px;
				}
			}
			.slidebar_ico {
				// margin-left: 5px;
				padding: .3em;
				border-radius: 5px;
				margin-left: .0em;
				cursor: pointer;
				transition: all 0.2s ease-in-out;
				svg {
					path {
						stroke: $white;
					}
				}
				&:hover {
					background: $white-light;
					color: $base;
					svg {
						path {
							stroke: $base;
						}
					}
				}
				@media screen and (max-width: $mobile) {
					margin-left: 1.5em;
				}
			}
			.slidebar_ico.PCM {
				// margin-left: 90px;
				margin-left: 8em;

				@media screen and (max-width: $mobile) {
					margin-left: 9.5em;
				}
			}

			@media screen and (max-width: $tablet) {
				padding: .7em 0;
			}
		}

		.navbar-collapse {
			.navbar-nav {
				position: relative;
				width: auto;

				.auth-nav {
					a {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: .4em 1em;
						.img_container {
							position: relative;
							display: inline-block;
							margin-right: 6px;
							height: 3.3em;
							width: 100em;
							max-width: 3.3em;

							img.profile_pic {
								// height: 3.3em;
								display: inline;
								margin: 0 auto;
								height: 100%;
								width: 100%;
								background-color: $white;
								border: 2px solid $white;
								border-radius: 50%;
								object-fit: cover;
							}
						}
						.details {
							text-transform: capitalize;
							display: inline-block;

							span.user_name {
								letter-spacing: 0px;
								font-weight: 600;
								font-size: 1.5rem;
								cursor: pointer;
							}
							p.user_email {
								margin: 0;
								text-transform: lowercase;
							}

							@media screen and (max-width: $mobile) {
								display: none;
							}
						}
						
					}
				}

				.public-nav {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.nav-link .ico {
						padding: .1em;
						position: absolute;
						top: 20%;
						visibility: hidden;
						opacity: 0;
						transform: translateY(50%) scale(2);
						transition: opacity 0.5s, transform 0.5s;
					}
					.nav-link:hover .ico {
						size: 2em;
						padding-bottom: .0rem;
						visibility: visible;
						opacity: 1;
						top: 15%;
						transform: translateY(0) scale(1.5);

						@media screen and (max-width: $mobile) {
							transform: translateY(0) scale(1);
						}
					}
					.nav-link span {
						padding: .1em .3em;
						position: relative;
						letter-spacing: 2px;
						font-weight: 600;
						font-size: 1.5rem;
						transform: translateY(0);
						transition: letter-spacing 0.5s, transform 0.5s;
					}
					.nav-link:hover span {
						padding: .1em .3em;
						// padding-bottom: .2rem;
						position: relative;
						// letter-spacing: 0.5px;
						transform: translateY(50%);
					}
					.nav-link {
						padding: 1em;
						// margin: 0 .2em;
						position: relative;
						// width: 20%;
						height: 100%;
						font-weight: 600;
						transition: all 0.3s linear;
						cursor: pointer;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						@media screen and (max-width: $mobile) {
							padding: 1em .6em;
						}
					}
					.nav-link:after {
						content: '';
						position: absolute;
						left: 0%;
						bottom: 0%;
						width: 100%;
						height: 0px;
						background-color: $white;
						transition: all ease-in-out 250ms;
					}
					.nav-link.active:after {
						height: 2px;
					}
					.nav-link:hover:after {
						height: 100%;
						width: 100%;
						outline: none;
						background-color: hsla(0,0%,100%,.3);
						transition: 0.4s;
					}
					.nav-link:hover, a.nav-link:focus, a.nav-link.active {
						color: $white;
					}
		
					@media screen and (max-width: $laptop) {
						width: 80%;
					}
					@media screen and (max-width: $tablet) {
						flex-direction: row;
						width: 100%;
					}
				}
			}
		}
		
		.not-collapse {
			flex-basis: 0%;
		}
	} 
}

.user-navbar {
	// @media screen and (max-width: $tablet) {
	// 	margin: .5em .2em 0;
	// }
	.navbar-toggler{
		display: none;
		visibility: hidden;
		opacity: 0;
	}
	.navbar-header{
		// width: 100%;
		margin-right: 0;
	}
	.auth-nav .nav-link {
		padding: 0;
	}
}