$mobile-s : 376px;
$mobile : 576px;    
$tablet : 992px;    
$laptop : 1024px;   
$laptop-l : 1600px;   
$tv : 1824px;   


html {
    font-size: 75%;
}


@media screen and (max-width: $mobile) {
    html {
        font-size: 75%!important;
    }
    .signup_image img.reg_left {
        display: none;
    }
}



@media screen and (max-width: $tablet) {
    html {
        font-size: 75%;
    }
}




@media screen and (min-width: $tv) {
    html {
        font-size: 90%;
    }
}