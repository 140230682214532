@import '../../sass/variables.scss';

.social-icon {
    width: auto;
    height: auto;
    .icon {
        display: inline-block;
        border: 1px solid transparent;
        border-radius: 50%;
        padding: 10px;
        svg {
            height: 20px;
            width: 20px;
        }
    }

    #Facebook {
        color: #3b5998;
        background: rgba(59,89,152,.15);
        &:hover {
            color: #3b5998;
            background: rgba(59,89,152,.4);
        }
    }

    #Twitter {
        color: #1da1f2;
        background: rgba(29,161,242,.15);
        &:hover {
            color: #1da1f2;
            background: rgba(29,161,242,.4);
        }
    }

    #Linkedin {
        color: #0077b5;
        background: rgba(0,119,181,.15);
        &:hover {
            color: #0077b5;
        background: rgba(0,119,181,.4);
        }
    }

    #Instagram {
        color: #e1306c;
        background: rgba(225,48,108,.15);
        &:hover {
            color: #e1306c;
            background: rgba(225,48,108,.4);
        }
    }

    #Youtube {
        color: #ff0000;
        background: rgba(255,0,0,.15);
        &:hover {
            color: #ff0000;
            background: rgba(255,0,0,.4);
        }
    }

    #GitHub {
        color: #ffffff;
        background: rgba(22, 27, 34, .4);
        &:hover {
            color: #ffffff;
            background: rgba(22, 27, 34, .8);
        }
    }

    #Website {
        color: $white;
        background: $base-glassMorph;
        &:hover {
            color: $white;
        background: $base;
        }
    }
}

