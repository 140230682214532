@import '../../sass/variables.scss';
@import '../../sass/responsive.scss';


.mandatory {
    color: $ad;
}


// Base
.form-input {
    position: relative;
    top: 0;
    left: 0;
    padding: 1.8em 0;

    .field {
        position: relative;
        top: 0;
        left: 0;
        color: $input;
        font-size: 18px;
        width: 100%;

        input {
            position: relative;
            width: 100%;
            height: 28px;
            display: block;
            background-color: transparent;
            color: $text;
            border: none;
            border-radius: 0;
            border-bottom: 1.5px solid $input;
            box-sizing: border-box;
            padding: .1em .2em;
            font-size: 1.3rem;
            z-index: 1;
            cursor: text;
            // line-height: 0;
        }

        input:focus {
            border-bottom: 2px solid $base;
            outline: 0;
            box-shadow: none !important;
        }

        input.hasError {
            border-bottom: 2px solid $input-error;
        }

        input.noError {
            border-bottom: 2px solid $input-ok;
        }

        label {
            width: 100%;
            position: absolute;
            left: 0;
            top: 100%;
            transform: translateY(-100%);
            transition: .25s ease;
        }

        input:focus+.form-label,
        // input:not(:placeholder-shown)+.form-label,
        input:valid+.form-label {
            top: 0%;
            font-size: 12px;
            color: $base;
        }
    }

    .error_form {
        position: absolute;
        // left: 0;
        color: $input-error;
        font-weight: 400;
        font-size: 12px;
        transition: all .8s;
    }
}


//  Email
.field-email {
    input:focus+.form-label,
    input:not(:placeholder-shown)+.form-label,
    input:valid+.form-label {
        top: 0%;
        font-size: 12px;
        color: $base;
    }
}

//  Password
.field-password {
    input:focus+.form-label,
    input:not(:placeholder-shown)+.form-label,
    input:valid+.form-label {
        top: 0%;
        font-size: 12px;
        color: $base;
    }
    input {
        padding-right: 2em!important;
    }
    .password-visibility {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        cursor: pointer;
    }
}


//  Select
.field {
    .arrow {
        border-color: #999 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        content: " ";
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        width: 0;
        height: 0;
        z-index: 4;
        cursor: pointer;
    }

    .arrow.open {
        border-color: transparent transparent $base;
        border-width: 0 5px 5px;
    }

    .OptionDetails {
        display: none;
        background-color: $body;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 15px 16.83px .17px rgba(0, 0, 0, .05);
        box-sizing: border-box;
        padding: .5em;
        margin-top: .2em;
        max-height: 20em;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 5;
        -webkit-overflow-scrolling: touch;
    }

    .OptionDetails.open {
        display: block;
    }

    .option {
        box-sizing: border-box;
        color: $text;
        cursor: pointer;
        display: block;
        padding: .2em .4em;
        border-radius: 5px;
    }

    .option.selected,
    .option:hover {
        background-color: $base;
        color: $white;
    }

    .option-null {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}


//  Date
.field {

    input.not-focused::-webkit-datetime-edit,
    input.not-focused::-webkit-calendar-picker-indicator {
        display: none;
    }

    input.focused::-webkit-datetime-edit,
    input.focused::-webkit-calendar-picker-indicator {
        display: inline-block;
    }

    input:valid.not-focused::-webkit-datetime-edit,
    input:valid.not-focused::-webkit-calendar-picker-indicator {
        display: inline-block;
    }

    input.focused::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
}


//  Mobile No
.field {
    input::-webkit-inner-spin-button {
        display: none;
    }
}


//  Profile Pic
.field {
    input[type=file] {
        display: none;
        cursor: pointer;
    }

    .img-holder {
        position: relative;
        display: inline-block;
        height: 10em;
        width: 10em;
        // width: 100%;
        // max-width: 10em;

        .glass-border {
            height: 100%;
            width: 100%;
        }

        label {
            display: inline-block;
            width: auto;
            left: auto;
            right: 5%;
            top: 95%;
            text-align: end;
            padding: 5.5px 10px;
            backdrop-filter: blur(5px);
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            cursor: pointer;
        }

        label:hover {
            background: $base-glassMorph;
            color: $white;
        }
    }
}


//  Tags
#tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;

    .tag {
        width: auto;
        // height: 32px;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        margin: 0 8px 8px 0;
        list-style: none;
        border-radius: 5px;
        color: $white;
        background: $base;

        .tag-close-icon {
            display: block;
            text-align: center;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 50%;
            margin-left: 8px;
            color: $base-dark;
            background: $white;
            cursor: pointer;
        }
    }
}


//  Url
.field-url {
    input:focus+.form-label,
    input:not(:placeholder-shown)+.form-label,
    input:valid+.form-label {
        top: 0%;
        font-size: 12px;
        color: $base;
    }
}


//  Favorite
.field-favorite {
    display: inline-block;
    padding: 1em;
    border: 1px dashed $base;
    border-radius: 50%;
    transition: all .2s ease-out;
    cursor: pointer;
    &:hover {
        background: $base-glassMorph;
    }
    svg {
        animation: favorite .2s ease-out;
    }
}
@keyframes favorite {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.4);
    }
    100% {
        transform: scale(1);
    }
}
.field-favorite.no {
    color: $input;
    &:hover {
        color: $white;
    }
}
.field-favorite.ok {
    color: $input-error;
    &:hover {
        color: $input-error;
    }
}


//  Text Area
.form-input-textarea {
    padding: .8em 0;
    font-size: 14px;

    textarea.form-control {
        font-size: 16px;
    }
}


.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 480px;
    padding: 0 8px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;

    &:focus-within {
        border: 1px solid #0052cc;
    }

    input {
        flex: 1;
        border: none;
        height: 46px;
        font-size: 14px;
        padding: 4px 0 0 0;

        &:focus {
            outline: transparent;
        }
    }
}


// Check Box
.form-input-agreement {
    position: relative;
    font-size: 13px;
    padding: 10px 0;

    .form-input-line {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        input[type=checkbox] {
            display: inline-block;
            margin-right: 10px;
            margin-top: 1px;
        }

        input[type=checkbox]:focus {
            box-shadow: 0 0 0 0.25rem transparent;
        }

        input[type=checkbox]:checked {
            background-color: $base;
            border-color: $base;
        }
    }

    #agreement_error_message {
        position: absolute;
        left: 0;
        padding-left: 20px;
        margin: 0;
        color: $input-error;
        font-weight: 400;
        font-size: 12px;
        transition: all .8s;
    }
}


fieldset {
    position: relative;
    border: 1px solid $base;
    border-radius: 5px;
    padding: 14px;
    color: $base;
    legend {
        position: absolute;
        top: -15px;
        display: inline-block;
        background-color: $body;
        width: auto;
        padding: 0 5px;
    }
}

.upper-social-links {
    @media screen and (max-width: $laptop) {
        display: none;
    }
    @media screen and (min-width: $laptop) {
        display: inline-block;
    }
}
.lower-social-links {
    @media screen and (max-width: $laptop) {
        display: inline-block;
    }
    @media screen and (min-width: $laptop) {
        display: none;
    }
}


.form-input.display-table-search {
    width: 25em;
    @media screen and (max-width: $mobile) {
        width: 100%;
    }
}

// Search Contact
.form-input.search {
    .search-result {
        display: none;
        // background-color: $white;
        border-radius: 5px;
        backdrop-filter: blur(6px);
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 15px 16.83px .17px rgba(0, 0, 0, .2);
        box-sizing: border-box;
        padding: 0em;
        margin-top: 5px;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        max-height: 25em;
        z-index: 5;
        -webkit-overflow-scrolling: touch;

        .list-group-item {
            box-sizing: border-box;
            color: $base;
            background-color: inherit;
            font-size: 1.1rem;
            font-weight: 500;
            cursor: pointer;
            display: block;
            padding: 0;
            border: 0;
            outline: 0;
            text-decoration: none;

            .item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: .8em 2em;

                .img-border {
                    position: relative;
                    display: inline-block;
                    height: 3em;
                    width: 100%;
                    max-width: 3em;

                    .profile_pic {
                        display: inline;
                        height: 100%;
                        width: 100%;
                        background-color: #fff;
                        border: 2px solid #415860;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                }
            }

            :hover {
                background-color: $base;
                color: $white;

                .profile_pic {
                    border: 2px solid $white!important;
                }
            }
        }

        .option-null {
            padding: 1em;
            text-align: center;
            font-size: 1.1rem;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .search-result.open {
        display: block;
    }
    ::-webkit-scrollbar {
        background-color: inherit;
    }
}