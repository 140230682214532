@import '../../sass/responsive.scss';
@import '../../sass/variables.scss';

.features {
    // padding: 12em;
    position: relative;
    text-align: center;
    height: 100%;
    width: 100%;
    margin-top: 10em;
    @media (max-width: calc(#{$laptop-l} - 300px)) {
        margin-top: 5em;
    }
    @media screen and (max-width: $laptop) {
        margin-top: 4em;
    }
    @media screen and (max-width: $tablet) {
        margin-top: 35em;
    }
    @media screen and (max-width: calc(#{$tablet} - 200px)) {
        margin-top: 30em;
    }
    @media screen and (max-width: $mobile) {
        margin-top: 22em;
    }

    .text {
        text-align: center;
        color: $text;
        padding: 0 clamp(2em, 8cqi, 10em);
        h1 {
            font-size: clamp(3rem, 6cqi, 4rem);
            font-weight: 600;
            margin-bottom: .4em;
        }
        p {
            font-size: clamp(1.2rem, 2cqi, 1.5rem);
            font-weight: 450;
            text-align: justify;
        }
        
    }

    .hover_me {
        position: absolute;
        display: inline-flex;
        justify-content: flex-end;
        top: 30%;
        right: 10%;
        width: 100%;
        max-width: 20em;
        user-select: none;
        @media screen and (max-width: $tablet) {
            display: none;
        }
        .arrow_with_text {
            color: $text;
            stroke: $text;
            text-align: end;
            h3 {
                color: inherit;
            }
            svg {
                position: absolute;
                top: -70px;
                right: 215px;
                width: 150px;
                height: 150px;
                margin: 0 auto;
                rotate: 96deg;
                .draw-arrow {  
                    stroke-width: 2;
                    stroke: inherit; 
                    fill: none;
                    stroke-dasharray: 400;
                    stroke-dashoffset: 400; 
                    animation-duration: 4s;
                    animation-fill-mode: forwards;
                    animation-name: draw;
                    &.tail-1 {
                        animation-delay: .8s;
                    }
                    &.tail-2 {
                        animation-delay: 1s;
                    }
                }
            }
            @keyframes draw {
                to {
                    stroke-dashoffset: 0;
                }
            }
        }
    }

    .manage_ring {
        position: relative;
        padding-top: 2em;
        height: 100vh;
        // padding: 20em;
        /* text-align: center; */
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $mobile) {
            padding-top: 0;
            height: 80vh;
        }

        .ring {
            position: relative;
            border: 2.5px solid rgba(23, 163, 149, 0.3);
            border-radius: 50%;
            user-select: none;
            padding: clamp(4em, 10cqi, 6em);
        }

        .ring_img {
            position: absolute;
            display: inline-block;
            height: auto;
            width: 100%;
            // max-width: 4em;
            max-width: clamp(3.5em, 8cqi, 4.5em);
            cursor: pointer;

            .img_wrapper {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                // isolation: isolate;

                img {
                    display: inline;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    z-index: 2;
                }
                h5 {
                    position: absolute;
                    top: 0%;
                    padding: 5px 20px;
                    margin: 0;
                    font-size: 1rem;
                    color: $text;
                    background-color: $white;
                    border-radius: 5px;
                    box-shadow: 0 10px 15px .20px rgba(0, 0, 0, .1);
                    z-index: 1;
                    opacity: 0;
                    transition: .4s ease all;
                    filter: blur(5px);
                    transform: translateY(0px) rotateY(30deg) rotateX(10deg) scale(.2)
                }
                h5.t-start {
                    text-align: start;
                }
                h5.t-end {
                    text-align: end;
                }
                h5.wide {
                    min-width: 12em;
                }
                h5.wide-lg {
                    min-width: 12.5em;
                }
            }
            
            &:hover {
                h5 {
                    opacity: 1;
                    filter: blur(0);
                    z-index: 3;
                }
            }
        }

        .ring_1 {
            .ring_img.one.easy2use {
                top: 2%;
                right: 16%;
                &:hover {
                    h5 {
                        transform:
                            translateY(-52px) translateX(40px) rotateY(12deg) rotateX(12deg) scale(1);
                    }
                }
            }
            .ring_img.two.customization {
                top: 85%;
                right: 16%;
                &:hover {
                    h5 {
                        transform:
                            translateY(-52px) translateX(50px) rotateY(12deg) rotateX(12deg) scale(1);
                    }
                }
                @media screen and (max-width: $mobile) {
                    &:hover {
                        h5 {
                            transform:
                                translateY(-52px) translateX(20px) rotateY(12deg) rotateX(12deg) scale(1);
                        }
                    }
                }
            }
            .ring_img.three.profile {
                top: 84%;
                right: 75%;
                &:hover {
                    h5 {
                        transform:
                        translateY(-50px) translateX(-30px) rotateY(-20deg) rotateX(10deg) scale(1)
                    }
                }
            }
            .ring_img.four.auth {
                top: 0%;
                right: 68%;
                &:hover {
                    h5 {
                        transform:
                            translateY(-50px) translateX(-40px) rotateY(-20deg) rotateX(10deg) scale(1);
                    }
                }
            }

            .ring_2 {
                .ring_img.one.dob {
                    top: -6%;
                    right: 30%;
                    &:hover {
                        h5 {
                            transform:
                                translateY(-52px) translateX(50px) rotateY(12deg) rotateX(12deg) scale(1);
                        }
                    }
                }
                .ring_img.two.world {
                    top: 25%;
                    right: -6%;
                    &:hover {
                        h5 {
                            transform:
                                translateY(-52px) translateX(50px) rotateY(12deg) rotateX(12deg) scale(1);
                        }
                    }
                    @media screen and (max-width: $mobile) {
                        &:hover {
                            h5 {
                                transform:
                                    translateY(-52px) translateX(-10px) rotateY(12deg) rotateX(12deg) scale(1);
                            }
                        }
                    }
                }
                .ring_img.three.tags {
                    top: 68%;
                    right: 0%;
                    &:hover {
                        h5 {
                            transform:
                                translateY(-45px) translateX(50px) rotateY(10deg) rotateX(10deg) scale(1);
                        }
                    }
                    @media screen and (max-width: $mobile) {
                        &:hover {
                            h5 {
                                transform:
                                    translateY(-45px) translateX(10px) rotateY(10deg) rotateX(10deg) scale(1);
                            }
                        }
                    }
                }
                .ring_img.four.zodiac {
                    top: 92%;
                    right: 45%;
                    &:hover {
                        h5 {
                            transform:
                            translateY(-50px) translateX(-50px) rotateY(-20deg) rotateX(10deg) scale(1)
                        }
                    }
                }
                .ring_img.five.address {
                    top: 60%;
                    right: 88%;
                    &:hover {
                        h5 {
                            transform:
                            translateY(-50px) translateX(-20px) rotateY(-20deg) rotateX(10deg) scale(1)
                        }
                    }
                }
                .ring_img.six.relation {
                    top: 15%;
                    right: 84%;
                    &:hover {
                        h5 {
                            transform:
                            translateY(-50px) translateX(-30px) rotateY(-20deg) rotateX(10deg) scale(1)
                        }
                    }
                }

                .ring_3 {
                    .center_img {
                        position: relative;
                        display: inline-block;
                        height: auto;
                        width: 100%;
                        max-width: 7em;
                        z-index: -2;

                        img {
                            display: inline;
                            height: 100%;
                            width: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    .ring_img.one.addC {
                        top: 3%;
                        right: 0%;
                        &:hover {
                            h5 {
                                transform:
                                    translateY(-50px) 
                                    translateX(40px) 
                                    rotateY(10deg) 
                                    rotateX(10deg) 
                                    scale(1);
                            }
                        }
                    }
                    .ring_img.two.searchC {
                        top: 72%;
                        right: 1%;
                        &:hover {
                            h5 {
                                transform:
                                    translateY(-52px) translateX(40px) rotateY(12deg) rotateX(12deg) scale(1);
                            }
                        }
                    }
                    .ring_img.three.editC {
                        top: 66%;
                        right: 78%;
                        &:hover {
                            h5 {
                                transform:
                                    translateY(-50px) translateX(-40px) rotateY(-20deg) rotateX(10deg) scale(1)
                            }
                        }
                    }
                    .ring_img.four.deleteC {
                        top: 7%;
                        right: 74%;
                        &:hover {
                            h5 {
                                transform:
                                translateY(-50px) translateX(-50px) rotateY(-20deg) rotateX(10deg) scale(1)
                            }
                        }
                    }
                }
            }
        }
    }
}