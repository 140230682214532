@import '../../sass/variables.scss';
@import '../../sass//responsive.scss';

.questions {
    padding: 4em;
    @media screen and (max-width: $mobile) {
        padding: 2em;
    }
    .container {
        text-align: center;
        .text {
            color: $text;
            margin-bottom: 2.5em;
            h1 {
                font-size: clamp(2rem, 5cqi, 4rem);
                font-weight: 600;
                margin-bottom: .2em;
            }
            p {
                font-size: clamp(1.2rem, 2cqi, 1.5rem);
                font-weight: 450;
                span {
                    color: $base;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .accordion {
            max-width: 70em;
            width: 100%;
            margin: 0 auto;
            transition: all 650ms ease-out;

            .accordion-item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                border-radius: 5px;
                overflow: hidden;
                background: rgb(255, 255, 255);
                border: 1px solid;
                border-left: 15px solid gray;
                // box-shadow: 0 15px 16.83px .17px rgba(0, 0, 0, .05);
                margin-top: 2em;

                &.orange {
                    border-color: $tab-orange;
                    background: $tab-orange-light;
                    color: $tab-orange-dark;
                    stroke: $tab-orange-dark;
                    box-shadow: 0px 15px 20px 0px $tab-orange-opacity;
                    .close::before,
                    .close::after {
                        background: $tab-orange-dark;
                    }
                }
                &.blue {
                    border-color: $tab-blue;
                    background: $tab-blue-light;
                    color: $tab-blue-dark;
                    stroke: $tab-blue-dark;
                    box-shadow: 0px 15px 20px 0px $tab-blue-opacity;
                    .close::before,
                    .close::after {
                        background: $tab-blue-dark;
                    }
                }
                &.green {
                    border-color: $tab-green;
                    background: $tab-green-light;
                    color: $tab-green-dark;
                    stroke: $tab-green-dark;
                    box-shadow: 0px 15px 20px 0px $tab-green-opacity;
                    .close::before,
                    .close::after {
                        background: $tab-green-dark;
                    }
                }
                &.pink {
                    border-color: $tab-pink;
                    background: $tab-pink-light;
                    color: $tab-pink-dark;
                    stroke: $tab-pink-dark;
                    box-shadow: 0px 15px 20px 0px $tab-pink-opacity;
                    .close::before,
                    .close::after {
                        background: $tab-pink-dark;
                    }
                }
                &.violet {
                    border-color: $tab-violet;
                    background: $tab-violet-light;
                    color: $tab-violet-dark;
                    stroke: $tab-violet-dark;
                    box-shadow: 0px 15px 20px 0px $tab-violet-opacity;
                    .close::before,
                    .close::after {
                        background: $tab-violet-dark;
                    }
                }
                &.cyan {
                    border-color: $tab-cyan;
                    background: $tab-cyan-light;
                    color: $tab-cyan-dark;
                    stroke: $tab-cyan-dark;
                    box-shadow: 0px 15px 20px 0px $tab-cyan-opacity;
                    .close::before,
                    .close::after {
                        background: $tab-blue-dark;
                    }
                }
                
                .accordion-header {
                    button {
                        width: 100%;
                        padding: 1.2em 2cqi;
                        background-color: inherit;
                        color: inherit;
                        font-size: clamp(1.2rem, 2.8cqi, 2rem);
                        font-weight: 600;
                        justify-content: space-between;
                        &:focus {
                            box-shadow: none;
                        }
                        &::after {
                            display: none;
                        }

                        .close {
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            overflow: hidden;
                            transition: transform 1s;

                            &::before,
                            &::after {
                                content: '';
                                position: absolute;
                                height: .2em;
                                width: 90%;
                                top: 50%;
                                left: 0;
                                margin-top: -2.6px;
                                border-radius: 10px;
                                // background: black;
                            }
                        }
                        &[aria-expanded="true"] {
                            .close {
                                transform: rotate(-135deg);
                                &::before {
                                    transform: rotate(0deg);
                                }
                                &::after {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                        &[aria-expanded="false"] {
                            .close {
                                transform: rotate(0deg);
                                &::before {
                                    transform: rotate(0deg);
                                }
                                &::after {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }
                .accordion-body {
                    text-align: start;
                    position:relative;
                    padding: 1em 2cqi 2cqi;
                    color: $text;
                    font-size: clamp(1rem, 2cqi, 1.5rem);
                    font-weight: 450;
                    cursor:pointer;
                }
            }
        }
    }
}