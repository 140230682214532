@import '../../sass/variables.scss';
@import '../../sass/responsive.scss';

.pagination {
    padding: 1em 0 4em;
    text-align: center;
    // border: 1px solid red;
    ul {
        display: inline-flex;
        padding: 0;
        margin: auto;
        list-style: none;
        // border: 1px solid red;
        li {
            button {
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                margin: 0 .5rem;
                border-radius: 50%;
                color: $base;
                // background-color: $base-glassMorph;
                font-size: 1.4rem;
                font-weight: 400;
                svg {
                    height: 18px;
                    width: 18px;
                }
                &:hover {
                    color: $base;
                    background-color: $white-light;
                }
                @media screen and (max-width: $mobile) {
                    width: 38px;
                    height: 38px;
                    margin: 0 .2em;
                }
            }
            button.key {
                border: 1px solid $base;
                background-color: transparent;
                &:hover {
                    color: $white;
                    background-color: $base;
                }
            }
            button.disabled {
                border: 1px solid transparent;
                color: $white;
                background-color: $body-light;
                cursor: not-allowed;
                &:hover {
                    color: $white;
                    background-color: $body-light;
                }
            }
            button.active {
                color: $white;
                background-color: $base;
            }
            #first {
                margin-left: 0;
            }
            #last {
                margin-right: 0;
            }
        }
    }
}