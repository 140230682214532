@import'./variables.scss';

.card {
    .card-body {
            span.skeleton_icon {
                padding-left: 3em;
            }
            span.skeleton_text {
                width: 100%;
                padding-left: 5px;
            }
    }
}

.header {
    span.skeleton_text {
        width: 40em;
    }
}


.data-row.skeleton {
    span {
        line-height: 1.4;
        vertical-align: middle;
    }
    span.checkbox {
        width: 18px;
        height: 18px;
    }
    span.contact_name {
        width: 70%;
    }
    td.actions {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.8em;

        span.button {
            height: 100%;
            display: contents;
            
            .btn {
                height: 30px;
                width: 30px;
            }
        }
    }
    &:hover {
        background-color: $white!important;
    }
}
